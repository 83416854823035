import { Button, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import queryString from 'query-string';
import useQueryParams from '../../shared/use-query-params';

function PublicInvitesPage() {
  const location = useLocation();
  const query = useQueryParams();
  const search = useMemo(() => {
    const { c, ...params } = query;
    const _search = `?${queryString.stringify({
      c,
      p: location.pathname,
      s: JSON.stringify(params),
    })}`;
    return _search;
  }, [query, location]);
  return (
    <AuthPageTemplate>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
        {`Welcome!`}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 4 }}>
        {`Please create an account or sign in to view your invite.`}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 16,
        }}
      >
        <Button
          component={Link}
          to={`/sign-up${search}`}
          style={{ marginBottom: 24, minWidth: 256 }}
          variant="contained"
        >
          Create an Account
        </Button>
        <Button
          component={Link}
          color="secondary"
          to={`/sign-in${search}`}
          style={{ marginBottom: 16, minWidth: 256 }}
          variant="contained"
        >
          Sign In
        </Button>
      </div>
    </AuthPageTemplate>
  );
}

export default PublicInvitesPage;
