export const READ_MY_DEVICES = 'READ_MY_DEVICES';
export const CREATE_MY_DEVICE = 'CREATE_MY_DEVICE';
export const UPDATE_MY_DEVICE = 'UPDATE_MY_DEVICE';
export const DELETE_MY_DEVICE = 'DELETE_MY_DEVICE';

export const readMyDevicesAction = (
  // eslint-disable-next-line no-shadow
  myDevices,
  queryId = 'default',
) => ({
  type: READ_MY_DEVICES,
  payload: {
    myDevices,
    queryId,
  },
});

export const createMyDeviceAction = (myDevice, queryId = 'default') => ({
  type: CREATE_MY_DEVICE,
  payload: {
    myDevice,
    queryId,
  },
});

export const updateMyDeviceAction = (myDevice, queryId = 'default') => ({
  type: UPDATE_MY_DEVICE,
  payload: {
    myDevice,
    queryId,
  },
});

export const deleteMyDeviceAction = (myDeviceId, queryId = 'default') => ({
  type: DELETE_MY_DEVICE,
  payload: {
    myDeviceId,
    queryId,
  },
});

const initialMyDevices = { default: {} };

export function myDevices(state = initialMyDevices, action) {
  switch (action.type) {
    case READ_MY_DEVICES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.myDevices.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_MY_DEVICE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.myDevice._id]: action.payload.myDevice,
        },
      };
    case UPDATE_MY_DEVICE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.myDevice._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.myDevice._id]: {
            ...current,
            ...action.payload.myDevice,
          },
        },
      };
    }
    case DELETE_MY_DEVICE: {
      const { myDeviceId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [myDeviceId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
