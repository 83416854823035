import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { IMaskInput } from 'react-imask';

const phoneNumberMask1 = (text) => {
  if (text && text.length > 15) {
    return [
      '+',
      /\d/,
      /\d/,
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  if (text && text.length > 14) {
    return [
      '+',
      /\d/,
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

const phoneNumberMask2 = (text) => {
  if (
    text &&
    ((text.startsWith('+') && text.length > 12) ||
      (!text.startsWith('+') && text.length > 11))
  ) {
    return [
      '+',
      /\d/,
      /\d/,
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  if (
    text &&
    ((text.startsWith('+') && text.length > 11) ||
      (!text.startsWith('+') && text.length > 10))
  ) {
    return [
      '+',
      /\d/,
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { value: v, onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[
        {
          mask: '(000) 000-0000',
          lazy: false,
        },
        {
          mask: '+0 (000) 000-0000',
          lazy: false,
        },
        {
          mask: '+00 (000) 000-0000',
          lazy: false,
        },
      ]}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function PhoneNumberFieldElement({
  label,
  control,
  rules = {},
  name,
  required,
  placeholder,
  errors = {},
  style,
  caption,
  ...inputProps
}) {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <FormControl
          variant="standard"
          error={!!error}
          required={required}
          style={style}
        >
          <InputLabel htmlFor={`form-control-${name}`}>{label}</InputLabel>
          <Input
            id={`form-control-${name}`}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            // returnKeyType={nextRef ? 'next' : 'done'}
            // onSubmitEditing={nextRef ? () => nextRef.current.focus() : onDone}
            // accessoryRight={accessoryRight}
            // accessoryLeft={accessoryLeft}
            // keyboardType="phone-pad"
            inputComponent={TextMaskCustom}
            {...inputProps}
          />
          {(error || caption) && (
            <FormHelperText>{error.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default PhoneNumberFieldElement;
