import MySnackbar from '@my-theora/shared/src/components/MySnackbar';
import queryString from 'query-string';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import ScrollToTop from '../../components/ScrollToTop';
import useMyProfileUpdates from '../../shared/use-my-profile-updates';
import useProfile from '../../shared/use-profile';
import useQueryParams from '../../shared/use-query-params';
import GetTheAppPage from '../auth/GetTheAppPage';
import VerifyEmailPage from '../auth/VerifyEmailPage';
import DashboardPage from './DashboardPage';
import EditProfilePage from './EditProfilePage';
import Error404Page from './Error404Page';
import MyWatchesPage from './watches/MyWatchesPage';
import useMyDevices from './watches/use-my-devices';
import useMyJoon3DeviceUpdates from './watches/use-my-joon3-device-updates';
import useMyJoon3NowUpdates from './watches/use-my-joon3-now-updates';
import useMyUserXJoon3Updates from './watches/use-my-user-x-joon3-updates';
import ViewInvitePage from '../auth/ViewInvitePage';
import Public404PageNotFound from '../auth/Public404PageNotFound';
import AcceptInvitePage from '../auth/AcceptInvitePage';
import ResetPasswordPage from '../auth/ResetPasswordPage';

function ForwardAfterLogin() {
  const query = useQueryParams();
  const { p, s, c } = query;
  let search = '';
  if (s) {
    try {
      const params = JSON.parse(s);
      search = `?${queryString.stringify({ ...params, c })}`;
    } catch (err) {
      console.error(err);
    }
  }
  if (p) {
    return <Navigate to={`${p}${search}`} />;
  }
  return <Navigate to="/home" />;
}

function ForwardAfterSignUp() {
  const query = useQueryParams();
  const { p, s, c } = query;
  let search = '';
  if (s) {
    try {
      const params = JSON.parse(s);
      search = `?${queryString.stringify({ ...params, c })}`;
    } catch (err) {
      console.error(err);
    }
  }
  if (p) {
    return <Navigate to={`${p}${search}`} />;
  }
  return <Navigate to="/verify-email" />;
}

function ForwardAfterEmailVerification() {
  const query = useQueryParams();
  const { p, s, c } = query;
  let search = '';
  if (s) {
    try {
      const params = JSON.parse(s);
      search = `?${queryString.stringify({ ...params, c })}`;
    } catch (err) {
      console.error(err);
    }
  }
  if (p) {
    return <Navigate to={`${p}${search}`} />;
  }
  return <Navigate to="/get-the-app" />;
}

function MainRouter() {
  useMyProfileUpdates();
  useMyJoon3DeviceUpdates();
  useMyJoon3NowUpdates();
  useMyUserXJoon3Updates();
  useMyDevices();
  const profile = useProfile();
  return (
    <ScrollToTop>
      <Routes>
        {profile.emailVerified ? (
          <>
            <Route exact path="/" element={<ForwardAfterLogin />} />
            <Route exact path="/home" element={<GetTheAppPage />} />
            <Route exact path="/sign-in" element={<ForwardAfterLogin />} />
            <Route exact path="/sign-up" element={<ForwardAfterSignUp />} />
            <Route
              exact
              path="/verify-email"
              element={<ForwardAfterEmailVerification />}
            />
            <Route exact path="/mobile/invite" element={<ViewInvitePage />} />
            <Route exact path="/accept-invite" element={<AcceptInvitePage />} />
            <Route
              exact
              path="/mobile/reset-password"
              element={<ResetPasswordPage />}
            />
            <Route path="*" element={<Public404PageNotFound />} />
          </>
        ) : (
          <>
            <Route path="*" element={<VerifyEmailPage />} />
          </>
        )}
      </Routes>
      <MySnackbar />
    </ScrollToTop>
  );
}

export default MainRouter;
