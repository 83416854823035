import { useApolloClient } from '@apollo/client';
import { Logout, Person, Settings } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOutAction } from '../redux-store/auth-store';
import settings from '../settings';
import useProfile from '../shared/use-profile';

function AccountButton() {
  const profile = useProfile();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const client = useApolloClient();
  return (
    <>
      <Tooltip title={profile.name}>
        <Button
          onClick={handleClick}
          sx={{
            ml: 2,
            textTransform: 'none',
            color: theme.palette.text.primary,
          }}
          startIcon={
            <Avatar
              sx={{
                backgroundColor: settings.colors.red1,
                width: 40,
                height: 40,
              }}
              src={profile.primaryThumbnailUrl}
              alt="Profile Pic"
            >
              {profile.name && profile.name[0]}
            </Avatar>
          }
        >
          {profile.name}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* {profile.emailVerified && (
          <>
            <MenuItem component={Link} to="/profile">
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <Divider />
          </>
        )} */}
        <MenuItem
          onClick={() => {
            localStorage.removeItem('authToken');
            client.close();
            dispatch(signOutAction());
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountButton;
