import { parsePhoneNumber } from 'awesome-phonenumber';

export function formatPhoneNumberForStorage(ph) {
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.e164;
  }
  return null;
}
