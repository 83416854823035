import { useMemo, useRef } from 'react';
import Fuse from 'fuse.js';
import { shallowEqual, useSelector } from 'react-redux';

const searchOptions = {
  includeScore: true,
  keys: [
    'wearerName',
    'ownerName',
    'serialNo',
    '_id',
    'userXJoon3.nickName',
    'userXJoon3.user.name',
  ],
};

function defaultSort(a, b) {
  if (
    a &&
    a.userXJoon3 &&
    a.userXJoon3.nickName &&
    b &&
    b.userXJoon3 &&
    b.userXJoon3.nickName
  ) {
    return a.userXJoon3.nickName.localeCompare(b.userXJoon3.nickName);
  }
  if (a && a.userXJoon3 && a.userXJoon3.nickName) {
    return 1;
  }
  if (b && b.userXJoon3 && b.userXJoon3.nickName) {
    return -1;
  }
  return 0;
}

function useFilterMyDevices({ queryId = 'default', filters, sortBy }) {
  const data = useSelector(
    (store) =>
      (store.myDevices[queryId] && Object.values(store.myDevices[queryId])) ||
      [],
    shallowEqual,
  );
  const lastResult = useRef();
  const jsonFilters = JSON.stringify(filters);
  const filtered = useMemo(() => {
    let _data = data.filter((t) => t);
    if (filters && filters.search) {
      const fuse = new Fuse(_data, searchOptions);
      _data = fuse
        .search(filters.search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      lastResult.current = _data;
    } else {
      lastResult.current = _data;
    }
    if (sortBy) {
      return lastResult.current.sort(sortBy);
    }
    return lastResult.current.sort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonFilters, data, sortBy]);
  return filtered;
}

export default useFilterMyDevices;
