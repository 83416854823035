import { remoteQueries } from '@my-theora/shared/src/redux-store/remote-queries-store';
import { snackbar } from '@my-theora/shared/src/redux-store/snackbar-store';
import localForage from 'localforage';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import thunk from 'redux-thunk';
import client from '../apollo-client';
import settings from '../settings';
import { authState, profile, SIGN_OUT } from './auth-store';
import { users } from './users-store';
import { joonDevices } from './joon-devices-store';
import { userXJoon3s } from './user-x-joon3s-store';
import { geofences } from './geofences-store';
import { myDevices } from './my-devices-store';
import { joonNows } from './joon-nows-store';
import { responsiveMode, windowDimensions } from './window-store';
import { selectedCampusId } from './selected-campus';

const appReducer = combineReducers({
  authState,
  profile,
  windowDimensions,
  responsiveMode,
  users,
  joonDevices,
  userXJoon3s,
  geofences,
  joonNows,
  myDevices,
  remoteQueries,
  snackbar,
  selectedCampusId,
  settings: () => settings,
});

const rootReducer = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
    console.log('action', action);
  }
  let newState;
  if (action.type === SIGN_OUT) {
    newState = appReducer(undefined, action);
    const outgoingProfile = state.profile;
    localStorage.clear();
    localForage.clear();
    client.close();
  } else {
    newState = appReducer(state, action);
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('new state', newState);
  }
  return newState;
};

const persistConfig = {
  key: 'reduxRoot',
  storage: storage('reduxDb'),
  whitelist: ['authState', 'profile', 'selectedCampusId'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
