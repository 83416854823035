import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import darthVaderKid from '../../assets/darth-vader-kid.jpg';
import darthVaderKidFlower from '../../assets/darth-vader-kid-flower.jpg';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import settings from '../../settings';

function Public404PageNotFound() {
  const [source, setSource] = useState(darthVaderKid);
  const nonce = useRef(false);
  return (
    <AuthPageTemplate
      hideLogo
      suffix={
        <div style={{ padding: 16 }}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 8,
              color: settings.colors.gray,
            }}
          >
            Photo by{' '}
            <a href="https://unsplash.com/@rd421?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              R.D. Smith
            </a>{' '}
            on{' '}
            <a href="https://unsplash.com/photos/taQV2u1JM_4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </Typography>
        </div>
      }
    >
      <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
        404 Not Found
      </Typography>
      <img
        onLoad={() => {
          if (!nonce.current) {
            nonce.current = true;
            setTimeout(() => setSource(darthVaderKidFlower), 5000);
          }
        }}
        src={source}
        alt="darth-vader"
        style={{ width: '100%', marginBottom: 24 }}
      />
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
        {`But you can pretent this page exists.`}
      </Typography>
    </AuthPageTemplate>
  );
}

export default Public404PageNotFound;
