import { gql, useApolloClient, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Alert, Snackbar, Typography } from '@mui/material';
import { showMessageAction } from '@my-theora/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import useProfile from '../../shared/use-profile';
import { ProfileAttributes } from './constants';

export const verifyEmailFromAppMutation = gql`
  mutation VerifyEmailFromApp($code: String!) {
    verifyEmailFromApp(code: $code) ${ProfileAttributes}
  }
`;

export const resendVerificationCodeMutation = gql`
  mutation resendVerificationCode {
    resendVerificationCode
  }
`;

function VerifyEmailPage() {
  const [loading, setLoading] = useState();
  const [resending, setResending] = useState();
  const [verifyEmail] = useMutation(verifyEmailFromAppMutation);
  const [resendEmail] = useMutation(resendVerificationCodeMutation);
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const doSignUp = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const resp1 = await verifyEmail({
          variables: {
            code: values.code,
          },
        });
        if (!(resp1 && resp1.data && resp1.data.verifyEmailFromApp)) {
          throw new Error('Error verifyEmailFromApp');
        }
        dispatch(
          showMessageAction({
            _id: 'verify-email',
            severity: 'success',
            message: 'Email Verified!',
          }),
        );
        setEmailSent(true);
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'verify-email',
            severity: 'error',
            message: 'We apologize, an error has occurred',
          }),
        );
      }
      setLoading(false);
    },
    [verifyEmail, dispatch],
  );
  const doResendEmail = useCallback(async () => {
    setResending(true);
    try {
      await resendEmail({
        fetchPolicy: 'network-only',
      });
      dispatch(
        showMessageAction({
          _id: 'verify-email',
          severity: 'success',
          message: 'Email Resent!',
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'verify-email',
          severity: 'error',
          message: 'We apologize, an error has occurred',
        }),
      );
    }
    setResending(false);
  }, [resendEmail, dispatch]);
  const profile = useProfile();
  return (
    <AuthPageTemplate>
      {emailSent ? (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {`One moment ...`}
        </Typography>
      ) : (
        <FormContainer
          defaultValues={{ name: '', email: '' }}
          onSuccess={doSignUp}
        >
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
            {`Thanks for signing up!`}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
            {`Thanks for signing up!  We've sent an email to ${profile.email} with a confirmation code.  Please enter the code below.`}
          </Typography>
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="code"
            label="Confirmation Code"
            type="text"
            required
            disabled={loading}
          />
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Verify Email
            </LoadingButton>
          </div>
          <div style={{ textAlign: 'center', marginTop: 32 }}>
            Didn't get an email?{' '}
            <LoadingButton onClick={doResendEmail} loading={resending}>
              Resend
            </LoadingButton>
          </div>
        </FormContainer>
      )}
    </AuthPageTemplate>
  );
}

export default VerifyEmailPage;
