import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonDeviceAction,
  deleteJoonDeviceAction,
  updateJoonDeviceAction,
} from '../../../redux-store/joon-devices-store';
import { deleteMyDeviceAction } from '../../../redux-store/my-devices-store';

export const Joon3DeviceAttributes = gql`
  {
    _id
    desc
    ownerId
    ownerName
    wearerId
    wearerName
    serialNo
    imsi
    iccid
    msisdn
    fwVer
    appVer
    appFlavor
    fallAppVer
    fallAppFlavor
    confVer
    confStatus
    createdAt
    updatedAt
    deletedAt
  }
`;

export const Joon3DeviceUpdateAttributes = gql`
{
  type
  crud
  id
  new ${Joon3DeviceAttributes}
}
`;

export const myJoon3DeviceUpdatesSubscription = gql`
  subscription MyJoon3DeviceUpdates {
    myJoon3DeviceUpdates ${Joon3DeviceUpdateAttributes}
  }
`;

function useMyJoon3DeviceUpdates() {
  const queryId = 'default';
  const dispatch = useDispatch();
  useSubscription(myJoon3DeviceUpdatesSubscription, {
    variables: {},
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.myJoon3DeviceUpdates) {
          const updates = result.data.myJoon3DeviceUpdates;
          updates.forEach((update) => {
            console.log('update', update);
            switch (update.type) {
              case 'JOON_DEVICE_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoonDeviceAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoonDeviceAction(update.id, queryId));
                    dispatch(deleteMyDeviceAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useMyJoon3DeviceUpdates;
