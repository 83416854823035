import { gql } from '@apollo/client';

export const DeviceUserRoles = {
  CAREGIVER: {
    key: 'CAREGIVER',
    label: 'Caregiver',
  },
  ADMIN: {
    key: 'ADMIN',
    label: 'Admin',
  },
  OWNER: {
    key: 'OWNER',
    label: 'Owner',
  },
  WEARER: {
    key: 'WEARER',
    label: 'Wearer',
  },
};

export const MyGeofenceAttributes = gql`
  {
    _id
    name
    address {
      address1
      address2
      city
      state
      country
      zipCode
    }
    type
    center {
      lat
      lon
    }
    radius
    topLeft {
      lat
      lon
    }
    bottomRight {
      lat
      lon
    }
    points {
      lat
      lon
    }
    deviceId
    createdAt
    updatedAt
  }
`;
