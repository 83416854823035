import {
  Alert,
  Avatar,
  Box,
  Button,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { doUpload } from '@my-theora/shared/src/shared/file-upload';
import { generateUuid, toBase64 } from '@my-theora/shared/src/shared/utils';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { useApolloClient } from '@apollo/client';

const aspect = 1;
const scale = 1;
const rotate = 0;

function centerAspectCrop(mediaWidth, mediaHeight, _aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      _aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function getCroppedImg(image, pixelCrop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = pixelCrop.width * scaleX;
  canvas.height = pixelCrop.height * scaleY;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file.name = fileName;
      resolve(file);
    }, 'image/jpeg');
  });
}

function ProfilePhotoFieldInner({
  value,
  onChange,
  handleSubmit,
  profile,
  tags,
  fileType,
}) {
  const [uploaded, setUploaded] = useState();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef();
  const handleUploadClick = useCallback((event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      setUploaded(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState();
  const [progress, setProgress] = useState();
  const [newImage, setNewImage] = useState();
  const client = useApolloClient();
  const handleSave = useCallback(async () => {
    setLoading(true);
    setProgress(0);
    try {
      const childId = generateUuid();
      const file = await getCroppedImg(
        imgRef.current,
        completedCrop,
        `profilePhoto_${childId}.jpg`,
      );
      let base64Url;
      if (file.type.startsWith('image')) {
        base64Url = await toBase64(file);
      }
      setNewImage(base64Url);
      await doUpload({
        client,
        file: file,
        fileType,
        parentType: 'user',
        parentId: profile._id,
        childId: childId,
        tags,
        onProgress: (p) => setProgress(p),
      });
      onChange(childId);
      setUploaded(false);
      setShowMessage({
        severity: 'success',
        message: 'Successfully uploaded your image',
      });
      handleSubmit();
    } catch (err) {
      console.error(err);
      setShowMessage({
        severity: 'error',
        message: 'There was an error saving your image',
      });
    }
    setLoading(false);
  }, [client, completedCrop, profile, onChange, handleSubmit, tags, fileType]);

  return (
    <>
      {((value && newImage) || (profile && profile.primaryThumbnailUrl)) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ width: 200, height: 200, display: 'block', marginBottom: 1 }}
            src={newImage || (profile && profile.primaryThumbnailUrl)}
            alt="Profile Pic"
          />
          <label htmlFor="upload-input">
            <Button component="label">
              Upload Photo
              <input
                accept="image/*"
                id="upload-input"
                type="file"
                onChange={handleUploadClick}
                style={{ display: 'none' }}
              />
            </Button>
          </label>
        </div>
      )}
      {!(profile && profile.primaryThumbnailUrl) && !newImage && (
        <>
          <label htmlFor="upload-input">
            <Button
              startIcon={
                profile && profile.name && <Avatar>{profile.name[0]}</Avatar>
              }
              component="label"
            >
              Upload Photo
              <input
                accept="image/*"
                id="upload-input"
                type="file"
                onChange={handleUploadClick}
                style={{ display: 'none' }}
              />
            </Button>
          </label>
        </>
      )}
      <Modal
        open={!!uploaded}
        onClose={() => setUploaded(undefined)}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: '24px 16px',
            marginTop: 4,
            marginBottom: 4,
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Crop Your Image
          </Typography>
          {!loading && !!uploaded && (
            <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={uploaded}
                  style={{
                    maxHeight: '90vh',
                    maxWidth: '90vw',
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    objectFit: 'contain',
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <div style={{ marginTop: 16 }}>
                <Button variant="contained" onClick={handleSave}>
                  Done
                </Button>
              </div>
            </>
          )}
          {loading && (
            <div>
              <CircularProgressWithLabel value={progress} size={80} />
            </div>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={!!showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(undefined)}
      >
        <Alert
          onClose={() => setShowMessage(undefined)}
          severity={showMessage && showMessage.severity}
          sx={{ width: '100%' }}
        >
          {showMessage && showMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
}

function ProfilePhotoFieldElement({ name, onSubmit, profile, tags, fileType }) {
  const { control, handleSubmit } = useFormContext();
  console.log('profile', profile);
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <ProfilePhotoFieldInner
              handleSubmit={() => handleSubmit(onSubmit)()}
              value={value}
              onChange={onChange}
              name={name}
              profile={profile}
              tags={tags}
              fileType={fileType}
            />
          )}
        />
      </Box>
    </>
  );
}

export default ProfilePhotoFieldElement;
