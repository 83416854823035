import { gql, useApolloClient, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { Link, useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import { passwordStrength } from 'check-password-strength';
import { signIntoUserMutation } from './SignInPage';
import { useDispatch } from 'react-redux';
import { myProfileQuery } from './constants';
import { authStateChangedAction } from '../../redux-store/auth-store';
import PhoneNumberFieldElement from '../../components/PhoneNumberFieldElement';
import { formatPhoneNumberForStorage } from '../../shared/utils';

export const signUpFromAppMutation = gql`
  mutation SignUpFromApp($signUp: UserSignUpFromAppInput!) {
    signUpFromApp(signUp: $signUp) {
      token
    }
  }
`;

function SignUpPage() {
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [showMessage, setShowMessage] = useState();
  const [signUp] = useMutation(signUpFromAppMutation);
  const [signIn] = useMutation(signIntoUserMutation);
  const client = useApolloClient();
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const doSignUp = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { phone: unformatedPhone, ...values } = _values;
        const phone = formatPhoneNumberForStorage(unformatedPhone);
        const resp1 = await signUp({
          variables: {
            signUp: {
              phone,
              ...values,
            },
          },
        });
        if (!(resp1 && resp1.data && resp1.data.signUpFromApp)) {
          throw new Error('Error signUpFromApp');
        }
        const resp2 = await signIn({
          variables: {
            username: values.email,
            password: values.password,
          },
        });
        if (!(resp2 && resp2.data && resp2.data.signIntoUser)) {
          throw new Error('Error signIntoUser');
        }
        const token =
          resp2 &&
          resp2.data &&
          resp2.data.signIntoUser &&
          resp2.data.signIntoUser.token;
        if (!token) {
          throw new Error('Error signIntoUser');
        }
        localStorage.setItem('authToken', token);
        client.close();
        const resp3 = await client.query({
          query: myProfileQuery,
          fetchPolicy: 'network-only',
        });
        const profile = resp3 && resp3.data && resp3.data.myProfile;
        if (!profile) {
          throw new Error('Error myProfile');
        }
        dispatch(authStateChangedAction(profile));
        setEmailSent(true);
      } catch (err) {
        console.error(err);
        if (err.message.includes('Another user')) {
          setShowMessage({
            severity: 'error',
            message: 'Another user with that email address already exists',
          });
        } else {
          setShowMessage({
            severity: 'error',
            message: 'We apologize, there was an error.',
          });
        }
      }
      setLoading(false);
    },
    [signUp, signIn, client, dispatch],
  );
  return (
    <AuthPageTemplate>
      {emailSent ? (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {`One moment ...`}
        </Typography>
      ) : (
        <FormContainer
          defaultValues={{ name: '', email: '' }}
          onSuccess={doSignUp}
        >
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="name"
            label="Your Full Name"
            type="text"
            required
            disabled={loading}
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="email"
            label="Email"
            type="email"
            required
            disabled={loading}
          />
          <PhoneNumberFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="phone"
            label="Phone Number"
            required
            rules={{
              required: 'This field is required',
              validate: (value) => {
                if (formatPhoneNumberForStorage(value)) {
                  return true;
                }
                return 'Please enter a valid phone number';
              },
            }}
            disabled={loading}
          />
          <PasswordElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="password"
            label="New Password"
            disabled={loading}
            required
            validation={{
              validate: (value) => {
                const result = passwordStrength(value);
                if (result.id < 3) {
                  return 'Weak password, try making it longer, including numbers and symbols, or mixing upper and lower case.';
                }
                return undefined;
              },
            }}
          />
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Sign Up
            </LoadingButton>
          </div>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              Already have an account?{' '}
              <Button
                component={Link}
                to={`/sign-in${location.search}`}
                style={{}}
              >
                Sign In
              </Button>
            </Typography>
          </Box>
        </FormContainer>
      )}
      <Snackbar
        open={!!showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(undefined)}
      >
        <Alert
          onClose={() => setShowMessage(undefined)}
          severity={showMessage && showMessage.severity}
          sx={{ width: '100%' }}
        >
          {showMessage && showMessage.message}
        </Alert>
      </Snackbar>
    </AuthPageTemplate>
  );
}

export default SignUpPage;
