import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProfileAttributes } from '../pages/auth/constants';
import { authStateChangedAction } from '../redux-store/auth-store';
import {
  createUserAction,
  deleteUserAction,
  updateUserAction,
} from '../redux-store/users-store';
import useProfile from './use-profile';

export const myProfileUpdatesSubscription = gql`
  subscription MyProfileUpdates {
    myProfileUpdates {
      type
      crud
      id
      new ${ProfileAttributes}
    }
  }
`;

function useMyProfileUpdates() {
  const profile = useProfile();
  const result = useSubscription(myProfileUpdatesSubscription, {
    skip: false,
  });
  const resultId =
    result &&
    result.data &&
    result.data.myProfileUpdates &&
    result.data.myProfileUpdates.new &&
    result.data.myProfileUpdates.new.updatedAt;
  const dispatch = useDispatch();
  useEffect(() => {
    if (result && result.data && result.data.myProfileUpdates) {
      const update = result.data.myProfileUpdates;
      switch (update.type) {
        case 'USER_CRUD': {
          switch (update.crud) {
            case 'create':
              dispatch(createUserAction(update.new, 'single'));
              break;
            case 'update':
              if (update.id === profile._id) {
                dispatch(authStateChangedAction(update.new));
              } else {
                dispatch(updateUserAction(update.new, 'single'));
              }
              break;
            case 'delete':
              dispatch(deleteUserAction(update.id, 'single'));
              break;
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultId]);
}

export default useMyProfileUpdates;
