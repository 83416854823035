import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useQueryParams() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  return params;
}

export default useQueryParams;
