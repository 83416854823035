import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonNowAction,
  deleteJoonNowAction,
  updateJoonNowAction,
} from '../../../redux-store/joon-nows-store';
import { MyGeofenceAttributes } from './constants';

export const MyJoon3NowAttributes = gql`
  {
    _id
    screeOnSecs
    onChargerSecs
    onSecs
    stepCount
    verifiedFallCount
    cancelledFallCount
    sosCalls
    fwVer
    appVer
    appFlavor
    confVer
    signalLevel
    batteryLevel
    loc {
      type
      lat
      lon
      alt
      acc
      speed
      bearing
    }
    drain
    timezone
    isPoweredOn
    isCharging
    geofences ${MyGeofenceAttributes}
    updatedAt
  }
`;

export const MyJoon3NowUpdateAttributes = gql`
{
  type
  crud
  id
  new ${MyJoon3NowAttributes}
}
`;

export const myJoon3NowUpdatesSubscription = gql`
  subscription MyJoon3NowUpdates {
    myJoon3NowUpdates ${MyJoon3NowUpdateAttributes}
  }
`;

function useMyJoon3NowUpdates() {
  const queryId = 'default';
  const dispatch = useDispatch();
  useSubscription(myJoon3NowUpdatesSubscription, {
    variables: {},
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.myJoon3NowUpdates) {
          const updates = result.data.myJoon3NowUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'JOON_NOW_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoonNowAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoonNowAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoonNowAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoonNowAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useMyJoon3NowUpdates;
