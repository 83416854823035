import { Avatar, Button, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import queryString from 'query-string';
import useQueryParams from '../../shared/use-query-params';
import { useQuery } from '@apollo/client';
import { viewMyInvitationQuery } from './constants';
import useDeviceImageUrl from '../../shared/use-device-image-url';

function ViewInvitePage() {
  const location = useLocation();
  const query = useQueryParams();
  const { c: code } = query;
  const response = useQuery(viewMyInvitationQuery, {
    variables: {
      _id: code,
    },
    fetchPolicy: 'cache-first',
  });
  const invite = response && response.data && response.data.viewMyInvitation;
  const device = invite && invite.device;
  const invitedBy = invite && invite.invitedBy;
  const deviceImageUrl = useDeviceImageUrl(
    device,
    'wearerProfilePhoto',
    'wearerPrimaryThumbnailUrl',
  );
  const initials = device && device.wearerName && device.wearerName[0];
  return (
    <AuthPageTemplate hideLogo>
      {invite && invitedBy && (
        <div>
          <Typography
            style={{ textAlign: 'center', marginBottom: 16 }}
            variant="h3"
          >
            {invite.inviteeName}
          </Typography>
          <Typography
            style={{ textAlign: 'center' }}
          >{`You have been invited by ${invitedBy.name} to join the Theora® Care Team for:`}</Typography>
        </div>
      )}
      {device && (
        <div
          style={{
            paddingTop: 24,
            marginBottom: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <Avatar
            sx={{ height: 144, width: 144, mb: 3 }}
            src={deviceImageUrl}
            myStyle={{ marginBottom: 16 }}
          >
            {initials}
          </Avatar>
          <div style={{ marginBottom: 4 }}>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              {device.wearerName}
            </Typography>
          </div>
        </div>
      )}
      <div style={{ textAlign: 'center' }}>
        <Button
          component={Link}
          to={`/accept-invite${location.search}`}
          style={{ marginBottom: 24, minWidth: 256 }}
          variant="contained"
          color="success"
          size="large"
        >
          Accept
        </Button>
        <Divider sx={{ mb: 3 }} />
        <Button
          component={Link}
          to={`/home`}
          style={{ marginBottom: 16, minWidth: 256 }}
        >
          Ignore
        </Button>
      </div>
    </AuthPageTemplate>
  );
}

export default ViewInvitePage;
