import { useMemo } from 'react';

export const ImageSizes = {
  THUMB: {
    key: 'THUMB',
    label: 'Thumbnail',
  },
  SM: {
    key: 'SM',
    label: 'Small',
  },
  MD: {
    key: 'MD',
    label: 'Medium',
  },
  LG: {
    key: 'LG',
    label: 'Large',
  },
};

function useDeviceImageUrl(
  userXJoon3,
  attr = 'profilePhoto',
  fallbackAttr = 'primaryThumbnailUrl',
) {
  const deviceImageUrl = useMemo(() => {
    if (userXJoon3 && userXJoon3[attr] && userXJoon3[attr].images) {
      let image = userXJoon3[attr].images.find(
        (i) => i.sizeCode === ImageSizes.SM.key,
      );
      if (image) {
        return image.url;
      }
    }
    if (userXJoon3 && userXJoon3[fallbackAttr]) {
      return userXJoon3[fallbackAttr];
    }
    return undefined;
  }, [userXJoon3, attr, fallbackAttr]);
  return deviceImageUrl;
}

export default useDeviceImageUrl;
