import { Card, styled, Toolbar, Typography } from '@mui/material';
import React from 'react';
import logo from '../assets/logo512.png';
import settings from '../settings';
import useProfile from '../shared/use-profile';
import AccountButton from './AccountButton';

const Container = styled(Card)(({ theme }) => ({
  padding: '32px 64px',
  width: 500,
  [theme.breakpoints.down(500)]: {
    padding: '16px 32px',
    boxShadow: 'none',
    width: 'unset',
  },
}));

function AuthPageTemplate({ hideLogo, children, suffix }) {
  const profile = useProfile();
  return (
    <>
      <Toolbar>
        <div style={{ marginRight: 'auto' }} />
        {profile && <AccountButton />}
      </Toolbar>
      <div className="body">
        <Container>
          {!hideLogo && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    background: settings.colors.blue,
                    borderRadius: '50%',
                    height: 180,
                    width: 180,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                >
                  <img style={{ width: 180 }} src={logo} alt="logos" />
                </div>
              </div>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: 56,
                  top: 0,
                  position: 'relative',
                  mt: 0,
                  mb: 4,
                  textAlign: 'center',
                }}
              >
                Theora{' '}
                <span style={{ color: settings.colors.theoraLightBlue }}>
                  Link Pro
                </span>
                <span
                  style={{
                    color: settings.colors.theoraLightBlue,
                    fontSize: 24,
                    position: 'relative',
                    top: -22,
                    left: 2,
                  }}
                >
                  ™
                </span>
              </Typography>
            </>
          )}
          {children}
        </Container>
        {suffix}
      </div>
      <div style={{ height: 56 }} />
      <style jsx>{`
        .body {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: calc(100vh - 112px);
          flex-direction: column;
        }
      `}</style>
    </>
  );
}

export default AuthPageTemplate;
