export const READ_GEOFENCES = 'READ_GEOFENCES';
export const CREATE_GEOFENCE = 'CREATE_GEOFENCE';
export const UPDATE_GEOFENCE = 'UPDATE_GEOFENCE';
export const DELETE_GEOFENCE = 'DELETE_GEOFENCE';

export const readGeofencesAction = (
  // eslint-disable-next-line no-shadow
  geofences,
  queryId = 'default',
) => ({
  type: READ_GEOFENCES,
  payload: {
    geofences,
    queryId,
  },
});

export const createGeofenceAction = (geofence, queryId = 'default') => ({
  type: CREATE_GEOFENCE,
  payload: {
    geofence,
    queryId,
  },
});

export const updateGeofenceAction = (geofence, queryId = 'default') => ({
  type: UPDATE_GEOFENCE,
  payload: {
    geofence,
    queryId,
  },
});

export const deleteGeofenceAction = (geofenceId, queryId = 'default') => ({
  type: DELETE_GEOFENCE,
  payload: {
    geofenceId,
    queryId,
  },
});

const initialGeofences = { default: {} };

export function geofences(state = initialGeofences, action) {
  switch (action.type) {
    case READ_GEOFENCES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.geofences.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_GEOFENCE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.geofence._id]: action.payload.geofence,
        },
      };
    case UPDATE_GEOFENCE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.geofence._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.geofence._id]: {
            ...current,
            ...action.payload.geofence,
          },
        },
      };
    }
    case DELETE_GEOFENCE: {
      const { geofenceId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [geofenceId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
