const settings = {
  cacheTimeout: 300000,
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8004/graphql/',
  apiWsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8004/graphql/',
  colors: {
    black: '#0D0D0B',
    yellow: '#F2A341',
    orange: '#F27405',
    darkOrange: '#BF4904',
    darkerOrange: '#8C2703',
    lightGray: '#D9D1D0',
    lightBrown: '#8C5346',
    brown: '#59220E',
    darkGray: '#594842',
    gray: '#8B878C',
    red: '#F20505',
    pink: '#F2668B',
    darkPink: '#A6033F',
    lightPink: '#F2BBD5',
    white: '#ffffff',
    theoraNavyBlue: '#3457A6',
    theoraLightBlue: '#43B1E3',
    theoraDarkBlue: '#262262',
    theoraMiddleBlue: '#1F82C5',
    theoraPurple: '#792779',
    theoraYellow: '#F9D94A',
    theoraGreen: '#4EAE55',
  },
  screenSizes: {
    lg: 992,
    md: 600,
    sm: 400,
  },
  pageSize: 25,
  largePage: 50,
  smallPage: 5,
  querySize: 100,
};

export default settings;
