import MySnackbar from '@my-theora/shared/src/components/MySnackbar';
import queryString from 'query-string';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import useQueryParams from '../../shared/use-query-params';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import PublicInvitesPage from './PublicInvitesPage';
import EualPage from '../main/eula/EualPage';

function AuthRouter() {
  const query = useQueryParams();
  const location = useLocation();
  const { c, ...params } = query;
  const search = `?${queryString.stringify({
    c,
    p: location.pathname,
    s: JSON.stringify(params),
  })}`;
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to={`/sign-in${location.search}`} />}
        />
        <Route exact path="/sign-in" element={<SignInPage />} />
        <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          exact
          path="/mobile/reset-password"
          element={<ResetPasswordPage />}
        />
        <Route exact path="/sign-up" element={<SignUpPage />} />
        <Route exact path="/mobile/invite" element={<PublicInvitesPage />} />
        <Route exact path="/eula/v1" element={<EualPage />} />
        <Route path="*" element={<Navigate to={`/sign-in${search}`} />} />
      </Routes>
      <MySnackbar />
    </>
  );
}

export default AuthRouter;
