export const READ_REMOTE_QUERIES = 'READ_REMOTE_QUERIES';
export const CREATE_REMOTE_QUERY = 'CREATE_REMOTE_QUERY';
export const UPDATE_REMOTE_QUERY = 'UPDATE_REMOTE_QUERY';
export const INCREMENT_REMOTE_QUERY = 'INCREMENT_REMOTE_QUERY';
export const NEXT_REMOTE_QUERY = 'NEXT_REMOTE_QUERY';
export const DELETE_REMOTE_QUERY = 'DELETE_REMOTE_QUERY';
export const INCREMENT_ALL_REMOTE_QUERIES = 'INCREMENT_ALL_REMOTE_QUERIES';

export const readRemoteQueriesAction = (
  // eslint-disable-next-line no-shadow
  remoteQueries,
  queryId = 'default',
) => ({
  type: READ_REMOTE_QUERIES,
  payload: {
    remoteQueries,
    queryId,
  },
});

export const createRemoteQueryAction = (remoteQuery, queryId = 'default') => ({
  type: CREATE_REMOTE_QUERY,
  payload: {
    remoteQuery,
    queryId,
  },
});

export const updateRemoteQueryAction = (remoteQuery, queryId = 'default') => ({
  type: UPDATE_REMOTE_QUERY,
  payload: {
    remoteQuery,
    queryId,
  },
});

export const deleteRemoteQueryAction = (_id, queryId = 'default') => ({
  type: DELETE_REMOTE_QUERY,
  payload: {
    _id,
    queryId,
  },
});

export const incrementRemoteQueryAction = (
  remoteQueryId,
  queryId = 'default',
) => ({
  type: INCREMENT_REMOTE_QUERY,
  payload: {
    remoteQueryId,
    queryId,
  },
});

export const incrementAllRemoteQueriesAction = (queryId = 'default') => ({
  type: INCREMENT_ALL_REMOTE_QUERIES,
  payload: {
    queryId,
  },
});

export const nextRemoteQueryAction = (
  remoteQueryId,
  nextAfter,
  queryId = 'default',
) => ({
  type: NEXT_REMOTE_QUERY,
  payload: {
    remoteQueryId,
    nextAfter,
    queryId,
  },
});

const initialQueries = { default: {} };

export function remoteQueries(state = initialQueries, action) {
  switch (action.type) {
    case READ_REMOTE_QUERIES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.remoteQueries.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_REMOTE_QUERY:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.remoteQuery._id]: action.payload.remoteQuery,
        },
      };
    case UPDATE_REMOTE_QUERY: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.remoteQuery._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.remoteQuery._id]: {
            ...current,
            ...action.payload.remoteQuery,
          },
        },
      };
    }
    case DELETE_REMOTE_QUERY: {
      const { _id } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [_id]: undefined,
        };
        return prev;
      }, {});
    }
    case INCREMENT_REMOTE_QUERY: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.remoteQueryId]) ||
        undefined;
      const refetches = (current && current.refetches) || 0;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.remoteQueryId]: {
            ...current,
            refetches: refetches + 1,
            after: undefined,
          },
        },
      };
    }
    case INCREMENT_ALL_REMOTE_QUERIES: {
      return {
        ...state,
        [action.payload.queryId]: Object.entries(
          state[action.payload.queryId] || {},
        ).reduce((prev, [key, val]) => {
          const refetches = (val && val.refetches) || 0;
          prev[key] = {
            ...val,
            refetches: refetches + 1,
            after: undefined,
          };
          return prev;
        }, {}),
      };
    }
    case NEXT_REMOTE_QUERY: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.remoteQueryId]) ||
        undefined;
      const refetches = (current && current.refetches) || 0;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.remoteQueryId]: {
            ...current,
            refetches: refetches + 1,
            after: action.payload.nextAfter,
          },
        },
      };
    }
    default:
      return state;
  }
}
