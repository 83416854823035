import { gql } from '@apollo/client';

export const ProfileAttributes = gql`
  {
    _id
    username
    name
    email
    emailVerified
    phone
    role {
      permissions
    }
    tenantId
    primaryThumbnailUrl
    updatedAt
  }
`;

export const myProfileQuery = gql`
  query MyProfile {
    myProfile ${ProfileAttributes}
  }
`;

export const viewMyInvitationQuery = gql`
  query ViewMyInvitation($_id: ID!) {
    viewMyInvitation(_id: $_id) {
      _id
      inviteeName
      role
      nickName
      relationToWearer
      invitedById
      invitedBy {
        _id
        name
      }
      deviceId
      device {
        _id
        wearerName
        wearerProfilePhotoId
        wearerProfilePhoto {
          _id
          uploaded
          processed
          contentType
          images {
            size
            url
            height
            width
            sizeCode
          }
          updatedAt
        }
        wearerPrimaryThumbnailUrl
      }
      sentAt
      createdAt
      updatedAt
    }
  }
`;

export const MyUserXJoon3Attributes = gql`
  {
    _id
    role
    relationToWearer
    nickName
    userId
    deviceId
    tenantId
    profilePhotoId
    profilePhoto {
      _id
      uploaded
      processed
      contentType
      images {
        size
        url
        height
        width
        sizeCode
      }
      updatedAt
    }
    primaryThumbnailUrl
    createdAt
    updatedAt
  }
`;
