import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showMessageAction } from '@my-theora/shared/src/redux-store/snackbar-store';
import { generateUuid } from '@my-theora/shared/src/shared/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
} from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import useQueryParams from '../../shared/use-query-params';

const Container = styled('div')(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: '100%',
  },
}));

export const resetMyPasswordWithLinkMutation = gql`
  mutation ResetMyPasswordWithLink($code: String!, $password: String!) {
    resetMyPasswordWithLink(code: $code, password: $password)
  }
`;

export const interpretMyForgotPasswordLinkMutation = gql`
  mutation InterpretMyForgotPasswordLink($code: String!) {
    interpretMyForgotPasswordLink(code: $code) {
      _id
      username
      email
      emailVerified
      name
      primaryThumbnailUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

function ResetPasswordPage() {
  const location = useLocation();
  const queryParams = useQueryParams();

  const [interpretMyForgotPasswordLink] = useMutation(
    interpretMyForgotPasswordLinkMutation,
  );
  const [user, setUser] = useState();
  const [linkError, setLinkError] = useState();
  const nonce = useRef();
  useEffect(() => {
    async function doAsyncStuff() {
      try {
        const result = await interpretMyForgotPasswordLink({
          variables: {
            code: queryParams.c,
          },
        });
        if (
          !(result && result.data && result.data.interpretMyForgotPasswordLink)
        ) {
          throw new Error('Bad response');
        }
        setUser(result.data.interpretMyForgotPasswordLink);
      } catch (err) {
        console.error(err);
        setLinkError('You link is invalid or expired.');
      }
    }
    if (!nonce.current) {
      nonce.current = true;
      doAsyncStuff();
    }
  }, [interpretMyForgotPasswordLink, queryParams]);

  const [loading, setLoading] = useState(false);
  const [resetMyPasswordWithLink] = useMutation(
    resetMyPasswordWithLinkMutation,
  );
  const [done, setDone] = useState();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { password, ...values } = _values;
        await resetMyPasswordWithLink({
          variables: {
            code: queryParams.c,
            password,
          },
          fetchPolicy: 'network-only',
        });
        setDone(true);
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'reset-password',
            severity: 'error',
            message: 'There was an error resetting your password.',
          }),
        );
      }
      setLoading(false);
    },
    [resetMyPasswordWithLink, dispatch, queryParams],
  );
  return (
    <>
      <AuthPageTemplate>
        {done ? (
          <>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {`Your password has been reset.  Please try and sign in again.`}
            </Typography>
            <div style={{ textAlign: 'center', marginTop: 32 }}>
              <Button
                variant="contained"
                component={Link}
                to={`/sign-in${location.search}`}
                style={{}}
              >
                Back to Sign In
              </Button>
            </div>
          </>
        ) : (
          <>
            {linkError ? (
              <>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  {linkError}
                </Typography>
                <div style={{ textAlign: 'center', marginTop: 32 }}>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/sign-in${location.search}`}
                    style={{}}
                  >
                    Back to Sign In
                  </Button>
                </div>
              </>
            ) : (
              <FormContainer
                defaultValues={{ username: '', password: '' }}
                onSuccess={onSubmit}
              >
                {user && (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: 'center', mb: 2 }}
                  >
                    {`Welcome back ${user.name}.`}
                  </Typography>
                )}
                <PasswordElement
                  style={{ marginBottom: 32, width: '100%' }}
                  variant="standard"
                  name="password"
                  label="New Password"
                  disabled={loading}
                  required
                />
                <PasswordRepeatElement
                  style={{ marginBottom: 32, width: '100%' }}
                  variant="standard"
                  name="confirm"
                  label="Confirm New Password"
                  passwordFieldName="password"
                  disabled={loading}
                  required
                />
                <div style={{ textAlign: 'center', marginTop: 24 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    Reset Password
                  </LoadingButton>
                </div>
                <div style={{ textAlign: 'center', marginTop: 24 }}>
                  <Button
                    component={Link}
                    to={`/sign-in${location.search}`}
                    style={{}}
                  >
                    Back to Sign In
                  </Button>
                </div>
              </FormContainer>
            )}
          </>
        )}
      </AuthPageTemplate>
    </>
  );
}

export default ResetPasswordPage;
