import { gql, useApolloClient, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  FormContainer,
  TextFieldElement,
  PasswordElement,
} from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import { authStateChangedAction } from '../../redux-store/auth-store';
import { myProfileQuery } from './constants';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const Container = styled('div')(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: '100%',
  },
}));

export const signIntoUserMutation = gql`
  mutation SignIntoUser($username: String!, $password: String!) {
    signIntoUser(username: $username, password: $password) {
      token
    }
  }
`;

function SignInPage() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState();
  const [signIn] = useMutation(signIntoUserMutation);
  const [emailSent, setEmailSent] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch();
  const doSignUp = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const resp1 = await signIn({
          variables: {
            ...values,
          },
        });
        if (!(resp1 && resp1.data && resp1.data.signIntoUser)) {
          throw new Error('Error');
        }
        const token =
          resp1 &&
          resp1.data &&
          resp1.data.signIntoUser &&
          resp1.data.signIntoUser.token;
        if (!token) {
          throw new Error('Error');
        }
        localStorage.setItem('authToken', resp1.data.signIntoUser.token);
        client.close();
        const resp2 = await client.query({
          query: myProfileQuery,
          fetchPolicy: 'network-only',
        });
        const profile = resp2 && resp2.data && resp2.data.myProfile;
        if (!profile) {
          throw new Error('Error');
        }
        setEmailSent(true);
        dispatch(authStateChangedAction(resp2.data.myProfile));
        setShowMessage({
          severity: 'success',
          message: 'Sign in successful!',
        });
      } catch (err) {
        console.error(err);
        if (err.message.includes('does not exist')) {
          setShowMessage({
            severity: 'error',
            message: "We couldn't find a user with that email address",
          });
        } else if (
          err.message.includes('Username and/or password do not match')
        ) {
          setShowMessage({
            severity: 'error',
            message: 'Username and/or password do not match',
          });
        } else {
          setShowMessage({
            severity: 'error',
            message: 'We apologize, an error has occurred',
          });
        }
        setLoading(false);
      }
    },
    [signIn, client, dispatch],
  );
  return (
    <>
      <AuthPageTemplate>
        <Container>
          {emailSent ? (
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {`One moment ...`}
            </Typography>
          ) : (
            <FormContainer
              defaultValues={{ username: '', password: '' }}
              onSuccess={doSignUp}
            >
              <TextFieldElement
                style={{ marginBottom: 32, width: '100%' }}
                variant="standard"
                name="username"
                label="Username"
                type="text"
                disabled={loading}
                required
              />
              <PasswordElement
                style={{ marginBottom: 32, width: '100%' }}
                variant="standard"
                name="password"
                label="Password"
                disabled={loading}
                required
              />
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Sign In
                </LoadingButton>
              </div>
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <Button
                  component={Link}
                  to={`/forgot-password${location.search}`}
                  style={{}}
                >
                  Trouble Signin In?
                </Button>
              </div>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body1">
                  Don't have an account yet?{' '}
                  <Button
                    component={Link}
                    to={`/sign-up${location.search}`}
                    style={{}}
                  >
                    Create an Account
                  </Button>
                </Typography>
              </Box>
            </FormContainer>
          )}
        </Container>
        <Snackbar
          open={!!showMessage}
          autoHideDuration={6000}
          onClose={() => setShowMessage(undefined)}
        >
          <Alert
            onClose={() => setShowMessage(undefined)}
            severity={showMessage && showMessage.severity}
            sx={{ width: '100%' }}
          >
            {showMessage && showMessage.message}
          </Alert>
        </Snackbar>
      </AuthPageTemplate>
    </>
  );
}

export default SignInPage;
