import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createMyDeviceAction } from '../../../redux-store/my-devices-store';
import useFilterJoon3Devices from './use-filter-joon3-devices';
import useFilterJoon3Nows from './use-filter-joon3-nows';
import useFilterUserXJoon3s from './use-filter-user-x-joon3s';

function useMyDevices() {
  const queryId = 'default';
  const joonDevices = useFilterJoon3Devices({});
  const joonNows = useFilterJoon3Nows({});
  const userXJoon3s = useFilterUserXJoon3s({});

  const dispatch = useDispatch();
  useEffect(() => {
    joonDevices.forEach((device) => {
      const now = joonNows.find((n) => n._id === device._id);
      const userXJoon3 = userXJoon3s.find((u) => u.deviceId === device._id);
      dispatch(
        createMyDeviceAction(
          {
            ...device,
            now,
            userXJoon3,
          },
          queryId,
        ),
      );
    });
  }, [joonDevices, joonNows, userXJoon3s, dispatch, queryId]);
}

export default useMyDevices;
