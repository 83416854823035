import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createUserXJoon3Action,
  deleteUserXJoon3Action,
  updateUserXJoon3Action,
} from '../../../redux-store/user-x-joon3s-store';

export const MyUserXJoon3Attributes = gql`
  {
    _id
    role
    relationToWearer
    nickName
    userId
    deviceId
    tenantId
    profilePhotoId
    primaryThumbnailUrl
    createdAt
    updatedAt
  }
`;

export const MyUserXJoon3UpdateAttributes = gql`
{
  type
  crud
  id
  new ${MyUserXJoon3Attributes}
}
`;

export const myUserXJoon3UpdatesSubscription = gql`
  subscription MyUserXJoon3Updates {
    myUserXJoon3Updates ${MyUserXJoon3UpdateAttributes}
  }
`;

function useMyUserXJoon3Updates() {
  const queryId = 'default';
  const dispatch = useDispatch();
  useSubscription(myUserXJoon3UpdatesSubscription, {
    variables: {},
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.myUserXJoon3Updates) {
          const updates = result.data.myUserXJoon3Updates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'USER_X_JOON3_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createUserXJoon3Action(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateUserXJoon3Action(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateUserXJoon3Action(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteUserXJoon3Action(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useMyUserXJoon3Updates;
