import { Button, Link, Typography } from '@mui/material';
import React from 'react';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { isMobile } from 'react-device-detect';

function GetTheAppPage() {
  return (
    <AuthPageTemplate>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
        {`Congratulations you've completed your sign-up!`}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 4 }}>
        {`Download the Theora Link Pro™ Mobile App to continue.`}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 16,
          marginBottom: 32,
        }}
      >
        <Button
          component={Link}
          href="https://apps.apple.com/us/app/theora-link-pro/id6446599748"
          style={{ marginBottom: 24 }}
          variant="contained"
          startIcon={<AppleIcon />}
        >
          Apple App Store
        </Button>
        <Button
          component={Link}
          href="https://play.google.com/store/apps/details?id=com.clairvoyantnetworks.theoralink"
          variant="contained"
          startIcon={<GoogleIcon />}
        >
          Google Play Store
        </Button>
      </div>
      {isMobile && (
        <>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
            {`Have you already installed Theora Link Pro™?`}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: 16,
            }}
          >
            <Button
              component={Link}
              href="theora://theoralink.clairvoyantnetworks.com/mobile"
              style={{ marginBottom: 24 }}
              variant="contained"
              color="secondary"
              startIcon={<SendToMobileIcon />}
            >
              Go to the App
            </Button>
          </div>
        </>
      )}
    </AuthPageTemplate>
  );
}

export default GetTheAppPage;
