import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';
import { passwordStrength } from 'check-password-strength';
import { signIntoUserMutation } from './SignInPage';
import { useDispatch } from 'react-redux';
import {
  MyUserXJoon3Attributes,
  myProfileQuery,
  viewMyInvitationQuery,
} from './constants';
import { authStateChangedAction } from '../../redux-store/auth-store';
import { showMessageAction } from '@my-theora/shared/src/redux-store/snackbar-store';
import useQueryParams from '../../shared/use-query-params';
import useDeviceImageUrl from '../../shared/use-device-image-url';

export const acceptInviteMutation = gql`
  mutation acceptInvite(
    $rsvp: AcceptInviteInput!
  ) {
    acceptInvite(rsvp: $rsvp) {
      userXJoon3 ${MyUserXJoon3Attributes}
    }
  }
`;

function AcceptInvitePage() {
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [acceptInvite] = useMutation(acceptInviteMutation);
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQueryParams();
  const { c: code } = query;
  const response = useQuery(viewMyInvitationQuery, {
    variables: {
      _id: code,
    },
    fetchPolicy: 'cache-first',
  });
  const invite = response && response.data && response.data.viewMyInvitation;
  const device = invite && invite.device;
  const initials = device && device.wearerName && device.wearerName[0];
  const deviceImageUrl = useDeviceImageUrl(
    device,
    'wearerProfilePhoto',
    'wearerPrimaryThumbnailUrl',
  );
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const resp1 = await acceptInvite({
          variables: {
            rsvp: {
              inviteId: code,
              ...values,
            },
          },
        });
        if (!(resp1 && resp1.data && resp1.data.acceptInvite)) {
          throw new Error('Error acceptInvite');
        }
        dispatch(
          showMessageAction({
            _id: 'accept-invite',
            severity: 'success',
            message: 'Invite accepted!',
          }),
        );
        setEmailSent(true);
        navigate('/home');
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'accept-invite',
            severity: 'error',
            message: 'We apologize, there was an error.',
          }),
        );
      }
      setLoading(false);
    },
    [acceptInvite, dispatch, navigate, code],
  );
  return (
    <AuthPageTemplate hideLogo>
      {emailSent ? (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {`One moment ...`}
        </Typography>
      ) : (
        <FormContainer
          defaultValues={{
            relationToWearer: invite.relationToWearer,
            nickName: invite.nickName,
          }}
          onSuccess={onSubmit}
        >
          {device && (
            <div
              style={{
                paddingTop: 24,
                marginBottom: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'stretch',
              }}
            >
              <Avatar
                sx={{ height: 160, width: 160, mb: 2 }}
                src={deviceImageUrl}
                myStyle={{ marginBottom: 16 }}
              >
                {initials}
              </Avatar>
              <div style={{ marginBottom: 4 }}>
                <Typography variant="h3" style={{ textAlign: 'center' }}>
                  {device.wearerName}
                </Typography>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  Wearer
                </Typography>
              </div>
            </div>
          )}
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="relationToWearer"
            label="What is your relationship to the wearer?"
            placeholder="Relationship to wearer"
            type="text"
            disabled={loading}
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="nickName"
            label="What do you call the wearer?"
            placeholder="Device Label"
            type="text"
            disabled={loading}
          />
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Join Team
            </LoadingButton>
          </div>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Button
              component={Link}
              to={`/mobile/invite${location.search}`}
              style={{}}
            >
              Cancel
            </Button>
          </Box>
        </FormContainer>
      )}
    </AuthPageTemplate>
  );
}

export default AcceptInvitePage;
