import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showMessageAction } from '@my-theora/shared/src/redux-store/snackbar-store';
import { generateUuid } from '@my-theora/shared/src/shared/utils';
import React, { useCallback, useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AuthPageTemplate from '../../components/AuthPageTemplate';

const Container = styled('div')(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: '100%',
  },
}));

export const sendMyForgotPasswordLinkMutation = gql`
  mutation SendMyForgotPasswordLink($email: String!, $appUrl: String) {
    sendMyForgotPasswordLink(email: $email, appUrl: $appUrl)
  }
`;

function ForgotPasswordPage() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [sendMyForgotPasswordLink] = useMutation(
    sendMyForgotPasswordLinkMutation,
  );
  const [sent, setSent] = useState();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { email, ...values } = _values;
        await sendMyForgotPasswordLink({
          variables: {
            email,
          },
          fetchPolicy: 'network-only',
        });
        setSent(email);
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'forgot-password',
            severity: 'error',
            message: "We couldn't find a user associated with that email.",
          }),
        );
      }
      setLoading(false);
    },
    [sendMyForgotPasswordLink, dispatch],
  );
  return (
    <>
      <AuthPageTemplate>
        {sent ? (
          <>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {`We've sent a reset password link to ${sent}.  Check your inbox.`}
            </Typography>
            <div style={{ textAlign: 'center', marginTop: 32 }}>
              <Button
                variant="contained"
                component={Link}
                to={`/sign-in${location.search}`}
                style={{}}
              >
                Back to Sign In
              </Button>
            </div>
          </>
        ) : (
          <FormContainer
            defaultValues={{ username: '', password: '' }}
            onSuccess={onSubmit}
          >
            <TextFieldElement
              style={{ marginBottom: 32, width: '100%' }}
              variant="standard"
              name="email"
              label="Email"
              type="email"
              disabled={loading}
              required
            />
            <div style={{ textAlign: 'center', marginTop: 24 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Send Reset Password Link
              </LoadingButton>
            </div>
            <div style={{ textAlign: 'center', marginTop: 24 }}>
              <Button
                component={Link}
                to={`/sign-in${location.search}`}
                style={{}}
              >
                Back to Sign In
              </Button>
            </div>
          </FormContainer>
        )}
      </AuthPageTemplate>
    </>
  );
}

export default ForgotPasswordPage;
