import { Toolbar } from '@mui/material';
import React from 'react';
import AccountButton from '../../../components/AccountButton';
import useProfile from '../../../shared/use-profile';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const markdown = `
# TERMS OF SERVICE
          
Welcome to Theoracare™! Theoracare is a
communications and location service and device for caregivers and
guardians connect with, monitor, track and locate their loved ones.

**YOUR ACCESS AND USE OF THE THEORACARE SERVICE IS SUBJECT TO (1) THESE
TERMS OF SERVICE, (2) YOUR AGREEMENTS WITH THIRD PARTIES, SUCH AS YOUR
WIRELESS CARRIER, APP STORE/PLATFORM OR DEVICE PROVIDER AND (3) ALL
APPLICABLE LAWS, RULES AND REGULATIONS. YOU MUST BE AT LEAST 18 YEARS
OF AGE, THE AGE OF MAJORITY IN YOUR PLACE OF RESIDENCE TO USE THE
THEORACARE SERVICE. BY ACCESSING OR USING ANY PART OF THE THEORALINK
SERVICE, YOU ACKNOWLEDGE THAT YOU ACCEPT THESE TERMS OF SERVICE. IF
YOU DO NOT AGREE WITH ALL OF THESE TERMS, YOU MAY NOT USE ANY PORTION
OF THE THEORALINK SERVICE.**

## Theoracare Overview
The website, online service and mobile application (the "Theoralink App") and the mobile device (the “Theoraconnect Wearable Device”, collectively the “Theoracare Service”) are provided by Clairvoyant Networks, Inc. (“Theoracare”). The Theoracare Device can be configured, monitored and contacted through the Theoralink App. Please read the User Manual https://theoracare.com/contact-us/customer-support/ lfor more information. You may not resell use of, or access to, the Theoracare Service to any third party. You may not use the Theoracare Service for any commercial purpose without the prior written consent of Clairvoyant Networks, Inc.

## Consent for the Theoraconnect User
The Theoraconnect Device is intended to be worn and used by a person. By providing the Theoraconnect Device to a person, you represent and warrant to Clairvoyant Networks, Inc. that you are authorized by such person’s parent or guardian to enable and permit Clairvoyant Networks, Inc to operate the Theoracare Service and collect and use the information from the Theoraconnect Device used by the person as stated in these Terms of Service and the Privacy Policy https://clairvoyantnetworks.com/legal/

## Location Device / Emergency
The Theoracare Service is intended for use as a location device. However, the Theoracare Service is not a failsafe security system. Therefore, proper safety precautions should always be used with loved ones, despite the use of the Theoracare Service.

## Not 911 Compatible
The Theoraconnect Device is not compatible with 911 or similar emergency telephone number systems. Therefore, emergency calls from the Theoraconnect Device will go to a designated recipient (such as a parent or guardian), but will not be placed or forwarded to emergency services personnel.

## Not Compatible with Accessibility Features
The Theoraconnect Device is not designed to be compatible with telecommunications accessibility features or services such as TTY, audio prompts or similar features for those

## Recording Calls
By using the Theoracare Service, you expressly agree that we may record audio conversations between the Theoracare Device and the recipient of such call without further notification. It is your obligation to ensure that the recipient of such emergency calls from the Theoraconnect Device, such as the preset numbers that you program into the  Theoraconnect Device, consents to such recording.

## Privacy
The Theoraconnect Device is constantly collecting and transmitting data to the Theoracare Service, such as location data. Such detailed data collection and use is critical to the successful operation of the Theoracare Service. To learn more about our data collection and use practices, please read our Privacy Policy https://clairvoyantnetworks.com/legal/

## Who is our customer
Our customer is the individual who has registered the Theoraconnect Device. We are under no obligation to respond to requests, inquires, demands or communications from any party (such as another family member) other than our customer.

## Limited Warranty for the Device
Clairvoyant Networks, Inc  provides a limited warranty to you (if you are the initial and original purchaser of the Device) that the Device is free from defects in materials or workmanship under normal use when used in compliance with the User Manual for 1 year from the original date of purchase from an authorized Clairvoyant Networks, Inc. reseller, subject to the limitations and terms of the warranty disclosure. You should read Theoracare’s full warranty disclosure here https://clairvoyantnetworks.com/legal/. 

## The Theoralink App License
In order to use the Theoracare Service, you will need to download and install the Theoralink App. Clairvoyant Networks, Inc hereby grant you a non-exclusive, non-transferable, revocable, limited license to use the Theoralink App solely on devices and web browser installations that you own or manage. You represent and warrant to us that you have the right to install and operate the Theoralink App on such devices you are using. You may not remove, obscure, modify or disable any proprietary notices from Theoralink on any part of the Theoracare Service. You may not transfer this license to another party unless that party affirmatively accepts these Terms of Service. You may not use the Theoracare Service for any purpose that is illegal or potentially harmful to another party, including

## Uninstall Theoralink
You may uninstall the Theoralink App at any time by deleting it from your device in the same manner that you delete similar apps.

## Theoralink App Updates
Clairvoyant Networks, Inc may change, update or otherwise modify the Theoralink App or the software on the Theoraconnect Device at any time. If we do so, you may have to download a patch or new version in order to continue using the Theoracare Service. In addition, you agree that we may, without providing additional notice to you, automatically install updates to the Theoralink App or the Theoraconnect Device.

## Registration and Passwords
In order to use the Theoracare Service, you must register and install the Theoralink App. All information about you must be truthful, and you may not use any aliases or other means to mask your true identity. All email accounts must belong to you and not any other person. You are responsible for the security of your password and will be solely liable for any use or unauthorized use under your user name. If you suspect any unauthorized use of your user name, you should change your password and/or contact Theoracare immediately at 1.512.371.6164 or via email to support@theoracare.com.

## Accessories
The purchase of accessories from Clairvoyant Networks, Inc. may be subject to addition Terms of Service, in addition

## Termination of Access
In addition to any right or remedy that may be available to us under these Theoracare Terms of Service or applicable law, we may suspend, limit or terminate your account, or all or a portion of your access to the Theoracare Service, at any time with or without notice in the event (1) you have breached these Terms of Service, (2) such action is necessary to prevent harm to any party or liability to Clairvoyant Networks, Inc. 

## Communications with Clairvoyant Networks, Inc
Any communication or material you transmit to us by electronic mail, social media or otherwise, including any data, questions, comments, suggestions, or the like is, and will be treated as, nonconfidential and nonproprietary. Except to the extent expressly covered by our Privacy Policy, anything you transmit or post may be used by us for any purpose, including but not limited to, reproduction, disclosure, transmission, publication, broadcast, posting and/or ranking. Furthermore, you expressly agree that we are free to use any ideas, concepts, know-how, or techniques contained in any communication you send to us without compensation and for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products and services using such information.

## Proprietary Rights
We are the exclusive owner or authorized licensors of the Theoracare Service, including all copy, software, graphics, designs and all copyrights, trademarks and other intellectual property or proprietary rights contained therein. Without limiting the foregoing, you acknowledge that the Theoracare Service is the exclusive property of Clairvoyant Networks, Inc., and that you will not take any act inconsistent with our ownership rights or that would damage our ownership rights. No part of the Theoracare Service may be reverse engineered, decompiled or modified, nor may derivative works be created based on the Theoracare Service, without the prior written consent of Clairvoyant Networks, inc.. All rights not granted under these Terms of Service are reserved by Clairvoyant Networks, Inc.

## Disclaimer of Additional Warranties
EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THE SECTION ENTITLED “LIMITED WARRANTY FOR THE DEVICE”, ALL PARTS OF THE THEORACARE SERVICE ARE PROVIDED "AS IS" AND WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER AND WE DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE THEORACARE SERVICE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE COMPLETELY SECURE, UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE SHALL NOT BE LIABLE FOR THE USE OF THE THEORACARE SERVICE, INCLUDING, WITHOUT LIMITATION, THE CONTENT AND ANY ERRORS CONTAINED THEREIN PROVIDED BY THIRD PARTIES. 
CLAIRVOYANT NETWORKS, INC. DISCLAIMS ALL LIABILITY FOR, OR WARRANTIES FROM, THIRD PARTY SERVICES OR PRODUCTS USED IN CONNECTION WITH THE THEORACARE SERVICE, SUCH AS YOUR WIRELESS CARRIER, APP STORE/PLATFORM PROVIDER OR DEVICE MANUFACTURER.

IN NO EVENT WILL WE BE LIABLE UNDER ANY THEORY OF TORT, CONTRACT, STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR OTHER CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST DATA, LOST OPPORTUNITIES, COSTS OF COVER, EXEMPLARY, PUNITIVE, PERSONAL INJURY/WRONGFUL DEATH, EACH OF WHICH IS HEREBY EXCLUDED BY AGREEMENT OF THE PARTIES REGARDLESS OF WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

Some states prohibit the limitation of warranties as set forth herein. If any part of these warranty disclaimers or limitations of liability is found to be invalid or unenforceable for any reason or if we are otherwise found to be liable to you in any manner, then our aggregate liability for all claims under such circumstances for all liabilities, shall not exceed the greater of (1) the amount paid by you to purchase the Theoraconnect Device from an authorized reseller or (2) one hundred dollars ($100).

**Your sole remedies, and Clairvoyant Networks sole obligations, are as set forth under the section entitled “Limited Warranty for the Device”, for you to stop using the Theoracare Service or the dollar limitation set forth**

## Changes
We may change, suspend or terminate the Theoracare Service at any time without notice. In addition, these Theoracare Terms of Service may be changed at any time without prior notice. We will make such changes by posting them on the Theoracare site located at https://clairvoyantnetworks.com/legal/ or otherwise informing you of such changes. You should check for such changes frequently. Your continued access of the Theoracare Service after such changes conclusively demonstrates your acceptance of those changes.

## Indemnification
You agree to indemnify, defend and hold us and our representatives and agents harmless from and against any and all third party claims, demands, liabilities, costs or expenses, including attorney fees and costs, arising from, or related to (1) any breach by you of these Terms of Service, (2) your use of the Theoracare Service or (3) a violation by you of any applicable law, agreement or obligation to a third party.

## Governing Law
The laws of the State of Texas shall govern these Terms of Service and, subject to the arbitration section below, YOU HEREBY EXPRESSLY CONSENT TO EXCLUSIVE JURISDICTION AND VENUE IN THE COURTS LOCATED IN **TRAVIS COUNTY, TEXAS** FOR ALL MATTERS ARISING IN CONNECTION WITH THESE TERMS OF SERVICE OR YOUR ACCESS OR USE OF THE THEORACARE SERVICE. Any claims asserted by you in connection with the Theoracare Service must be asserted in writing to Clairvoyant Networks, Inc. within one (1) year of the date such claim first arose, or such claim is forever waived by you. Notwithstanding the arbitration section below, Clairvoyant Networks, Inc. may seek an injunction in a court of competent jurisdiction to the extent necessary to prevent any harm to any party or liability to Clairvoyant Networks, Inc.

## Arbitration
You hereby submit to the exclusive jurisdiction of the American Arbitration Association (“AAA”) in connection with any dispute relating to, concerning, or arising out of these Terms of Service or the Theorcare Service. The arbitration will be conducted before a single arbitrator and will be held at the AAA location in Travis County, Texas. Payment of all filing, administration, and arbitrator fees will be governed by the AAA’s rules, unless otherwise stated in this paragraph. In the event you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, Clairvoyant Networks, Inc.  will pay as much of your filing, administrative, and arbitrator fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive. The arbitration before the AAA shall proceed solely on an individual basis without the right for any claims to be arbitrated on a class action basis or on bases involving claims brought in a purported representative capacity on behalf of others. The arbitrator’s authority to resolve and make written awards is limited to claims between you and us alone. Claims may not be joined on consolidated unless agreed to in a writing by all parties. No arbitration award or decision will have any preclusive effect as to issues or claims in any dispute with anyone who is not a named party to the arbitration. If you initiate litigation or any other proceeding against Clairvoyant Networks, Inc. in violation of this paragraph, you agree to pay Clairvoyant Network’s reasonable costs and attorneys’ fees incurred in connection with its enforcement of this paragraph.

## Severability
If any part of these Terms of Service shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction, such provision shall be ineffective but shall not affect any other part of these Terms of Service.

## Waiver; Remedies:
The failure by us to partially or fully exercise any rights or the waiver of any breach of these Terms of Service by you, shall not prevent a subsequent exercise of such right by us or be deemed a waiver by us of any subsequent breach by you of the same or any other term of these Terms of Service. Our rights and remedies under these Terms of Service shall be cumulative, and the exercise of any such right or remedy shall not limit our right to exercise any other right or remedy.

## Export
You may not use or otherwise export or re-export the Theoracare Service except as authorized by United States law and the laws of the jurisdiction in which the Theoracare Service was obtained. In particular, but without limitation, the Theoracare Service may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List. By using the Theoracare Service, you represent and warrant that you are not located in any such country or on any such list.

## FCC
The Theoraconnect Device complies with part 15 of the FCC Rules. Operation is subject to the following two conditions: (1) The Theoraconnect Device may not cause harmful interference, and (2) This device must accept any interference received, including interference that may cause undesired operation. This equipment complies with FCC RF radiation exposure limits set forth for an uncontrolled environment. For body-worn operation and use near the head, the Theoraconnect Device has been tested and meets the FCC RF exposure guidelines when used with the wrist strap supplied with this product. Use of other accessories may not ensure compliance with FCC RF exposure guidelines. The maximum SAR value reported for body worn operation is 1.15 W/kg. The maximum SAR value reported against the head is 0.23 W/kg.

## Third Parties
Platform providers, such as AT&T®, Apple®, Google® and other third party carriers, platform or software providers and device manufacturers ("Platform Providers") are not parties to these Terms of Service. Except as otherwise agreed in writing between you and the applicable Platform Provider, Platform Providers have no obligation whatsoever to furnish any maintenance and support services with respect to the Theoracare Service. To the maximum extent permitted by applicable law, Platform Providers will have no other warranty obligation whatsoever with respect to the Theoracare Service. Except as otherwise agreed in writing between you and the applicable Platform Provider, You acknowledge that Platform Providers are not responsible for addressing any claims relating to the Theoracare Service or your possession and/or use of the Theoracare Service, including but not limited to: (i) product liability claims; (ii) any claim that the Theoracare Service fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. We, not the Platform Providers, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim by the Theoraconnect Device. Platform Providers are third party beneficiaries of these Terms of Service, and Platform Providers will have the right (and will be deemed to have accepted the right) to enforce these Terms of Service against you as a third party beneficiary thereof. You represent that you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and you are not listed on any U.S. Government list of prohibited or restricted parties.

## Contact Information
Should you have any questions regarding these Terms of Service, to report a violation of these Terms of Service or abuse of the Theoracare Service, to receive help in uninstalling the Theoralink App or for any other matter, you may contact us at: 1.512.371.6164 or via email at support@theoracare.com.

# Privacy Policy
## Overview
- Clairvoyant Networks, Inc.  provides a wireless device and service to help caregivers communicate with, monitor, track and locate their loved ones. This is a data driven service.
- The device will automatically collect the physical location from the device at all times when activated. Clairvoyant Networks, Inc. also receives data about the use of the device and the accompanying application. Clairvoyant Networks, Inc. may also record the audio of emergency calls from or to the device.
- Clairvoyant Networks Inc, will use this information to provide the location-based services and to contact the caregivers regarding the service and other information.

*By using the Theoracare Service, you expressly consent to the collection and use of information as described in our full Privacy Policy below:*

Last updated: July 21, 2023.

Clairvoyant Networks, Inc. (“Clairvoyant”) provides the website, online service and mobile application (the "Theoralink App") and the mobile device (the “Theoraconnect Device”, collectively the “Theoracare Service”). We have prepared this Privacy Policy so that you understand how data will be collected and used in connection with the Theoracare Service.

IF YOU DO NOT ACCEPT THIS PRIVACY POLICY, YOU MAY NOT ACCESS OR OTHERWISE USE THE THEORACARE SERVICE.

## Personal information collected through the Theoralink App
Through the Theoralink App we may collect your name, email address, date of birth, billing information and other personal information, as well as that of the person who will use the Theoraconnect Device. We do not collect personal information from you unless you voluntarily provide it to us.

## Location-based information collected through the Theoracare Service
The Theoraconnect Device automatically and constantly collects information about the physical location of the Theoraconnect Device when it is activated. This information is acquired through numerous methods, including GPS, cell towers and wi-fi hot spots, which are enhanced over time as we follow the Theoraconnect Device, collect more information and improve our tracking. You may not disable location-based data collection from the Theoraconnect Device. We may also collect such location-based information through your use of the Theoralink App.

## Calls/audio collected through the Theoracare Service
In the event an emergency call is placed from the Theoraconnect Device, we may record the audio of the conversation between the Theoraconnect Device and the recipient of such call.

## Non-personal or aggregate information that we collect
We automatically collect non-personal information from your use of the Theoracare Service, such as IP host address, device-specific identifiers, computer or smartphone configurations, pages viewed, time and usage habits on the Theoracare Service and similar data. It is possible at times when collecting non-personal information through automatic means that we may unintentionally collect or receive personal information that is mixed in with the non-personal information. While we will make reasonable efforts to prevent such incidental data collection, the possibility still exists. If we do inadvertently collect personal information, we will not intentionally use such personal information as if we had collected it with your consent. We may also aggregate demographic information collected from our users (such as the number of users in a particular State) in a manner which does not identify any one individual.

## Information Usage and Third Party Sharing
We will only use your information in a manner consistent with this Privacy Policy, unless you have specifically consented to another type of use, either at the time such information is collected or through some other form of consent from you or notification to you. Personal information and location-based information is treated as sensitive information by Clairvoyant Networks, Inc. and are subject to a heightened standard for sharing, as opposed to non-personal or de-identified information which may be shared with any number of parties.

Therefore, we may use your information to
- espond to your inquires or requests;
- send you information about the Theoracare Service (such as promotions, maintenance or security information);
- send you a limited number of offers for additional products and services that may interest you, but we will not provide your personal information to third parties for them to contact you directly for their own benefit without your consent;
- permit our vendors and subcontractors to perform services, provided they are under an obligation of confidentiality and do not use the information for their own benefit;
- comply with applicable law or legal process;
- provide to a third party for their own use, such as marketing, but only if you have specifically opted-in to such third party sharing;
- investigate suspected fraud, harassment, danger to persons or property or other violations of any law, rule or regulation, or the terms or policies for the Theoracare Service or our business partners;
- share with law enforcement and other parties trying to locate a lost child, including sharing the audio content of emergency calls;
- develop and enhance our own data, such as through tracking the location of your Theoraconnect Device, provided that any disclosures of such data does not specifically identify you, your Theoraconnect Device or your specific location;
- transfer information in connection with the sale or merger or change of control of Clairvoyant Networks, Inc. or the division responsible for the services with which your information is associated;
- share non-personal or de-identified information with any number of parties, including analytics companies, technology providers and other business partners; or
- combine it with data from other sources outside of your use of the Theoracare Service, such as data obtained from wi-fi access points within range of your Theoraconnect Device.

## Auto deletion of data
We may automatically delete data from a Theoraconnect Device or from the Theoralink App in connection with a returned or terminated account or device, or as necessary to protect against unauthorized access to the data.

## Cookies
"Cookies" are pieces of information that may be placed on your device by a service for the purpose of facilitating and enhancing your communication and interaction with that service. To the extent possible depending upon your device, we may use cookies (and similar items such as clear gifs, web beacons, tags, etc…) to customize your experience, to make your visit more convenient or to enable us to enhance the Theoracare Service. We may also use and place cookies on your device from our third party service providers in connection with the Theoracare Service, such as an analytics provider that helps us manage and analyze Theoracare Service usage. You may stop or restrict the placement of cookies on certain devices or flush them from your browser by adjusting your web browser preferences, in which case you may still use our Theoracare Service, but it may interfere with some of its functionality. Cookies and similar items are not used by us to automatically retrieve personal information from your device without your knowledge. Cookies are device and browser specific, so if you make a change to your environment we may reinstall a cookie.

## Security
The security of your information is very important to us. We use reasonable efforts internally at Clairvoyant Networks, Inc. to protect it from unauthorized access. However, due to the inherent open nature of the Internet and wireless communications, we cannot guarantee that your personal information will be completely free from unauthorized access by third parties, such when transferred over or through systems not within our exclusive control. Your use of our Theoracare Service demonstrates your assumption of this risk. We have put in place reasonable physical, electronic, and managerial procedures to safeguard the information we collect. Only those employees who need access to your information in order to perform their duties are authorized to have access to your personal information. For more information on protecting your privacy, please visit www.ftc.gov/privacy.

## Personal information from children
While the Theoraconnect Device is designed to be worn by a loved one, our customer is the applicable parent/guardian or other adult over 18 years of age who has enabled the Theoracare Service. By providing the Theoraconnect Device to a child, you represent and warrant to Clairvoyant Networks Inc. that you are authorized by such child’s parent or guardian to enable and permit Clairvoyant Networks, Inc. to operate the Theoracare Service and collect and use the information from the child as stated herein.

## Data retention
We may retain your information for as long as we feel that there is a business need or benefit to do so. This will include retaining location-based information.

## Data Access
Theoracare Data collected through use of the Theoraconnect Device and/or the Theoralink App, including location information, frequency of use and emergency contact information is generally not available to customers for their own, direct access. Please contact support@theoracare.com  for specific questions about access to any collected data.

## Other services
As a convenience to you, we may provide links to third party services from within our Theoracare Service. We are not responsible for the privacy practices or content of these third party services and such links should not be construed as an endorsement of any such third party services. When you link away from our Theoracare Service, you do so at your own risk.

## Changes to this Privacy Policy
We reserve the right, at our discretion, to change, modify, add, or remove portions from this Privacy Policy at any time. However, if at any time in the future we plan to use personal information or location-based data in a way that materially differs from this Privacy Policy, such as sharing such information with more third parties, we will post such changes here and provide you the opportunity to opt-out of such differing uses. Your continued use of the Theoracare Service following the posting of any changes to this Privacy Policy means you accept such changes.

## Opt-Out Process
You may unsubscribe from our marketing messages. All unsubscribe or opt-out requests should be sent to us at support@theoracare.com and we will process your request within a reasonable time after receipt. However, we are not responsible for removing your personal information from the lists of any third party who has previously been provided your information in accordance with this Privacy Policy or your consent, such as a sponsor. You should contact such third parties directly.

If you no longer want any data collected by Theoracare from the Theoraconnect Device, then you will need to deactivate the service.

## Theoracare Terms of Service
Use of the Theoracare Service is governed by, and subject to, the Terms of Service. This Privacy Policy is incorporated into such terms.

## United States of America
Our servers are maintained in the United States of America. By using the Theoracare Service, you freely and specifically give us your consent to export your personal information to the USA and to store and use it in the USA as specified in this Privacy Policy. You understand that data stored in the USA may be subject to lawful requests by the courts or law enforcement authorities in the USA.

## Contact
For questions or concerns relating to privacy, we can be contacted at: support@theoracare.com.

`;

function EualPage() {
  const profile = useProfile();
  return (
    <>
      <Toolbar>
        <div style={{ marginRight: 'auto' }} />
        {profile && <AccountButton />}
      </Toolbar>
      <div className="body">
        <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
      </div>
      <div style={{ height: 56 }} />
      <style jsx global>{`
        h2 {
          text-align: left;
          margin-bottom: 0;
          width: 100%;
        }
        p {
          text-align: left;
          width: 100%;
        }
        .body {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: calc(100vh - 112px);
          flex-direction: column;
          max-width: 1000px;
          padding-left: 16px;
          padding-right: 16px;
          margin: auto;
        }
      `}</style>
    </>
  );
}

export default EualPage;
