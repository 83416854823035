export const READ_JOON_NOWS = 'READ_JOON_NOWS';
export const CREATE_JOON_NOW = 'CREATE_JOON_NOW';
export const UPDATE_JOON_NOW = 'UPDATE_JOON_NOW';
export const DELETE_JOON_NOW = 'DELETE_JOON_NOW';

export const readJoonNowsAction = (
  // eslint-disable-next-line no-shadow
  joonNows,
  queryId = 'default',
) => ({
  type: READ_JOON_NOWS,
  payload: {
    joonNows,
    queryId,
  },
});

export const createJoonNowAction = (joonNow, queryId = 'default') => ({
  type: CREATE_JOON_NOW,
  payload: {
    joonNow,
    queryId,
  },
});

export const updateJoonNowAction = (joonNow, queryId = 'default') => ({
  type: UPDATE_JOON_NOW,
  payload: {
    joonNow,
    queryId,
  },
});

export const deleteJoonNowAction = (joonNowId, queryId = 'default') => ({
  type: DELETE_JOON_NOW,
  payload: {
    joonNowId,
    queryId,
  },
});

const initialJoonNows = { default: {} };

export function joonNows(state = initialJoonNows, action) {
  switch (action.type) {
    case READ_JOON_NOWS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joonNows.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON_NOW:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonNow._id]: action.payload.joonNow,
        },
      };
    case UPDATE_JOON_NOW: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joonNow._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonNow._id]: {
            ...current,
            ...action.payload.joonNow,
          },
        },
      };
    }
    case DELETE_JOON_NOW: {
      const { joonNowId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joonNowId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
