export const READ_USER_X_JOON3S = 'READ_USER_X_JOON3S';
export const CREATE_USER_X_JOON3 = 'CREATE_USER_X_JOON3';
export const UPDATE_USER_X_JOON3 = 'UPDATE_USER_X_JOON3';
export const DELETE_USER_X_JOON3 = 'DELETE_USER_X_JOON3';

export const readUserXJoon3sAction = (
  // eslint-disable-next-line no-shadow
  userXJoon3s,
  queryId = 'default',
) => ({
  type: READ_USER_X_JOON3S,
  payload: {
    userXJoon3s,
    queryId,
  },
});

export const createUserXJoon3Action = (userXJoon3, queryId = 'default') => ({
  type: CREATE_USER_X_JOON3,
  payload: {
    userXJoon3,
    queryId,
  },
});

export const updateUserXJoon3Action = (userXJoon3, queryId = 'default') => ({
  type: UPDATE_USER_X_JOON3,
  payload: {
    userXJoon3,
    queryId,
  },
});

export const deleteUserXJoon3Action = (userXJoon3Id, queryId = 'default') => ({
  type: DELETE_USER_X_JOON3,
  payload: {
    userXJoon3Id,
    queryId,
  },
});

const initialUserXJoon3s = { default: {} };

export function userXJoon3s(state = initialUserXJoon3s, action) {
  switch (action.type) {
    case READ_USER_X_JOON3S: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.userXJoon3s.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_USER_X_JOON3:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.userXJoon3._id]: action.payload.userXJoon3,
        },
      };
    case UPDATE_USER_X_JOON3: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.userXJoon3._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.userXJoon3._id]: {
            ...current,
            ...action.payload.userXJoon3,
          },
        },
      };
    }
    case DELETE_USER_X_JOON3: {
      const { userXJoon3Id } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [userXJoon3Id]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
